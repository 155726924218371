import React from 'react';

const HackCULogo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg
			id="prefix__Layer_1"
			data-name="Layer 1"
			viewBox="0 0 39.7 39.7"
			width="1em"
			height="1em"
			{...props}
		>
			<title>HackCU Logo</title>
			<defs>
				<style>
					{
						'.prefix__cls-1,.prefix__cls-3,.prefix__cls-5{fill:none;stroke-miterlimit:10}.prefix__cls-1,.prefix__cls-3{stroke:#0b9d58}.prefix__cls-1{stroke-width:8px}.prefix__cls-5{stroke:#db4536}'
					}
				</style>
			</defs>
			<path
				d="M307.14 288.51a19.35 19.35 0 10-19.35 19.35 19.35 19.35 0 0019.35-19.35z"
				transform="translate(-267.95 -268.66)"
				stroke="#547dbf"
				fill="none"
				strokeMiterlimit={10}
			/>
			<path
				className="prefix__cls-3"
				d="M279.13 285.33c-1.42.78-2.68 1.51-2.68 3.18s1.26 2.4 2.68 3.18M281.47 293c1.42.78 2.68 1.51 2.68 3.18a3.85 3.85 0 01-3.85 3.85M280.3 277a3.85 3.85 0 013.85 3.85c0 1.67-1.26 2.4-2.68 3.18"
				transform="translate(-267.95 -268.66)"
			/>
			<path
				className="prefix__cls-3"
				d="M280.3 300.07a3.85 3.85 0 01-3.85-3.85c0-2.13 2.05-2.73 3.85-3.86s3.85-1.72 3.85-3.85-2-2.73-3.85-3.86-3.85-1.72-3.85-3.85a3.85 3.85 0 013.85-3.8"
				transform="translate(-267.95 -268.66)"
			/>
			<path
				d="M298.72 291.94a3.86 3.86 0 10-3.86-3.86 3.86 3.86 0 003.86 3.86z"
				transform="translate(-267.95 -268.66)"
				stroke="#e19126"
				fill="none"
				strokeMiterlimit={10}
			/>
			<path
				className="prefix__cls-5"
				d="M289.72 280.8a3.86 3.86 0 013.86 3.85c0 1.68-1.27 2.4-2.68 3.19M288.55 289.18c-1.42.78-2.68 1.51-2.68 3.18a3.85 3.85 0 003.85 3.86"
				transform="translate(-267.95 -268.66)"
			/>
			<path
				className="prefix__cls-5"
				d="M289.72 296.22a3.86 3.86 0 003.86-3.86c0-2.13-2.05-2.72-3.86-3.85s-3.85-1.73-3.85-3.86a3.85 3.85 0 013.85-3.85"
				transform="translate(-267.95 -268.66)"
			/>
		</svg>
	);
};

const MemoHackCULogo = React.memo(HackCULogo);
export default MemoHackCULogo;
