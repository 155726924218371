import Link from '@components/Link';
import HackCULogo from '@images/HackCULogo';
import clsx from 'clsx';
import React, { HTMLAttributes, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';

export type HeaderProps = HTMLAttributes<HTMLHeadingElement>;

const Header: React.FC<HeaderProps> = (props) => {
	const navStyle =
		'text-white no-underline text-center mx-2 px-2 font-bold uppercase block hover:underline';

	const [open, setOpen] = useState(false);
	const heightMobile = open ? 'max-h-screen' : 'max-h-0';
	return (
		<header
			className="flex items-center flex-col md:flex-row py-2"
			style={{ zIndex: 10 }}
			{...props}
		>
			<div className="flex flex-row w-full md:w-auto px-3 md:px-0">
				<Link to="/">
					<div className="ml-0 md:ml-3">
						<HackCULogo fontSize="3.75rem" />
					</div>
				</Link>
				{/* TODO: fix weird slide bug when changing from mobile to desktop screen size */}
				<div
					className="flex ml-auto cursor-pointer visible md:invisible"
					onClick={() => setOpen(!open)}
					aria-expanded={open}
					aria-controls="nav-links"
					tabIndex={0}
					role="button"
					aria-label="Navigation trigger"
				>
					<div className="m-auto">
						<IconContext.Provider value={{ size: '2rem' }}>
							<FaBars />
						</IconContext.Provider>
					</div>
				</div>
			</div>

			<div
				className={clsx(
					'flex flex-col md:flex-row items-center md:max-h-full overflow-hidden ml-0 md:ml-auto mt-2 md:mt-0 pr-0 md:pr-3',
					// Note for desktop we ignore this with `md:max-h-full`
					heightMobile,
				)}
				id="nav-links"
				style={{
					maxHeight: heightMobile,
					transition: 'max-height 500ms ease',
				}}
				role="navigation"
			>
				<Link to="/" className={navStyle}>
					Home
				</Link>
				<Link to="/about-us" className={navStyle}>
					About Us
				</Link>
				<Link to="/team" className={navStyle}>
					Team
				</Link>
				<Link to="/events" className={navStyle}>
					Events
				</Link>
				<Link to="/sponsors" className={navStyle}>
					Sponsor Us
				</Link>
			</div>
		</header>
	);
};

export default Header;
